import Api from './Api.js';

export default {
  getChatByProject(filter, payload) {
    console.log('filter :', filter);
    return Api().get(`/v2/chat/project/${filter.id}/${filter.chatID}`);
  },
  addChat(payload) {
    return Api().post('/v2/chat', payload);
  },
  getAllProjectChats(id, payload) {
    return Api().get(`/v2/chat/${id}`, payload);
  },

  getAllProjects(filters, sort) {
    return Api().get('/v2/project');
  },
};
